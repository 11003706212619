import { NgModule,CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID} from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { PagesRoutingModule } from './pages-routing.module';
import { RossSharedModule } from 'src/@ross/shared.module';
import { LayoutModule } from '../shared/layout/layout.module';
import { registerLocaleData } from '@angular/common';
import localeEsAr from '@angular/common/locales/es-AR';
import { PagesComponent } from './pages.component';
import { MatDialogModule } from '@angular/material';
import { NgDatepickerModule } from 'ng2-datepicker';
import { AlertModule } from './shared/components/alert/alert.module';
import { ParcelasModule } from './parcelas/parcelas.module';

const localeAr: any = localeEsAr;
registerLocaleData(localeAr, 'es-Ar');

@NgModule({
  declarations: [
    PagesComponent,
 
  ],
  imports: [
    PagesRoutingModule,
    HttpClientModule,
    RossSharedModule,
    LayoutModule,
    MatDialogModule,
    NgDatepickerModule,
    AlertModule,
    ParcelasModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ { provide: LOCALE_ID, useValue: 'es-Ar' } ]
})
export class PagesModule { }
