import { RossNavigation } from 'src/@ross/types';

export const navigation: RossNavigation[] = [
    {
        id: 'dashboard',
        title: 'Inicio',
        icon: 'home',
        type: 'item',
        url: '/pages/dashboard',
        permissionRequiered: 'VERURLINICIO'
    },
    {
        id: 'parcelas',
        title: 'Parcelas',
        icon: 'view_quilt',
        type: 'item',
        url: '/pages/parcelas/listado',
        permissionRequiered: 'VERURLPARCELA'
    },
    {
        id: 'casuisticas',
        title: 'Casuisticas',
        icon: 'bookmarks',
        type: 'item',
        url: '/pages/casuisticas/listado',
        permissionRequiered: 'VERURLCASUISTICA'
    },
    {
        id:'seguridad',
        title: 'Seguridad',
        icon: 'security',
        type: 'collapsable',
        permissionRequiered: 'VERURLSEGURIDAD',
        children: [
            // {
            //     id: 'permisos',
            //     title: 'Permisos',
            //     icon: 'lock_open',
            //     type: 'item',
            //     url: '/pages/permisos/listado',
            //     permissionRequiered: 'VERURLPERMISO'
            // },
            {
                id: 'roles',
                title: 'Roles',
                icon: 'supervisor_account',
                type: 'item',
                url: '/pages/roles/listado',
                permissionRequiered: 'VERURLROL'
            },
            {
                id: 'usuarios',
                title: 'Usuarios',
                icon: 'supervised_user_circle',
                type: 'item',
                url: '/pages/usuarios/listado',
                permissionRequiered: 'VERURLUSUARIO'
            },
        ]
    } 
]
