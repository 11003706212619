import { NgModule } from '@angular/core';

import { ParcelasFormRoutingModule } from './parcelas-form-routing.module';
import { ParcelasFormComponent } from './parcelas-form.component';
import { MatSelectModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { AngularMaterialModule } from 'src/app/shared/angular-material.module';
import { RossSharedModule } from '../../../../@ross/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FilterSelectedPipe } from '../pipes/filter-selected.pipe';
import { TiposCuentaValuacionService } from '../../shared/services/tipos-cuenta-valuacion/tipos-cuenta-valuacion.service';
import { NumericDirective } from "../../shared/directives/numeric.directive";

@NgModule({
  declarations: [
    FilterSelectedPipe,
    ParcelasFormComponent,
    NumericDirective,
  ],
  imports: [
    ParcelasFormRoutingModule,
    MatSelectModule,
    MatFormFieldModule,
    AngularMaterialModule,
    RossSharedModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
  ],
  providers: [TiposCuentaValuacionService]
})
export class ParcelasFormModule { }
