import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { RossNavigationService } from 'src/@ross/components/navigation/navigation.service';


@Component({
    selector       : 'ross-navigation',
    templateUrl    : './navigation.component.html',
    styleUrls      : ['./navigation.component.scss'],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RossNavigationComponent implements OnInit
{
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _rossNavigationService: RossNavigationService,
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    async ngOnInit()
    {
        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this._rossNavigationService.getCurrentNavigation();

        // Subscribe to the current navigation changes
        this._rossNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                // Load the navigation
                this.navigation = this._rossNavigationService.getCurrentNavigation();
                // Mark for check
                this._changeDetectorRef.markForCheck();
            }
        );

        // Subscribe to navigation item
        merge(
            this._rossNavigationService.onNavigationItemAdded,
            this._rossNavigationService.onNavigationItemUpdated,
            this._rossNavigationService.onNavigationItemRemoved
            ).pipe(takeUntil(this._unsubscribeAll))
                .subscribe(() => {
                // Mark for check
                this._changeDetectorRef.markForCheck();
            }
        );
    }
}
