import { NgModule,NO_ERRORS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';

import { VerticalLayout1Component } from './layout-1.component';

import { RossSharedModule } from 'src/@ross/shared.module';
import { RossSidebarModule } from 'src/@ross/components';
import { ContentModule } from '../../components/content/content.module';
import { FooterModule } from '../../components/footer/footer.module';
import { ToolbarModule } from '../../components/toolbar/toolbar.module';
import { NavbarModule } from '../../components/navbar/navbar.module';

// import { IsGrantedDirective } from '../../../../roles-permissions/is-granted.directive';




@NgModule({
    declarations: [
        VerticalLayout1Component,
        // IsGrantedDirective
    ],
    imports     : [
        RouterModule,

        RossSharedModule,
        RossSidebarModule,

        ContentModule,
        FooterModule,
        NavbarModule,
        ToolbarModule
    ],
    exports     : [
        VerticalLayout1Component
    ],
    schemas: [NO_ERRORS_SCHEMA]
})
export class VerticalLayout1Module
{
}
