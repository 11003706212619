import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SpinnerService } from '../spinner/spinner.service';
import { environmentLoader } from 'src/app/shared/services/environment-loader.service';
// import { environment } from '../../../environments/environment';
let environment = environmentLoader()


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    public pendingRequests: number = 0;
    private exceptions: string[] = ['login'];
    
    constructor(
        private router: Router,
        private spinnerService: SpinnerService,
    ){ }

public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('intercept');
    console.log('environment.settings',environment.settings);
    this.pendingRequests++;
    this.spinnerService.show();

    req = req.clone({
        url: `${environment.settings.urlBE}${req.url}`
    });
    req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
    req = req.clone({ headers: req.headers.set('Accept', 'application/json') });

    let currentToken = JSON.parse(localStorage.getItem('currentToken'));
        
        let exclude = this.exceptions.map((ex) => {
            return req.url.search(ex) === -1 //If search === -1 the route isn't an exception
        }).some((ex) => !ex);

        if (!exclude) {
            req = req.clone({ headers: req.headers.set('Authorization', `${currentToken}`)});
        }
    return next.handle(req)
        .pipe(
            finalize(() =>{
                this.pendingRequests--;
                if (this.pendingRequests == 0)
                    this.spinnerService.hide();
                })
        );
}

}


