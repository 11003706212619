import { Directive, Input, TemplateRef, ViewContainerRef, ɵɵNgOnChangesFeature } from '@angular/core';
import { AuthenticationService } from 'src/app/auth/shared/auth.service';

@Directive({
  selector: '[appIsGranted]'
})
export class IsGrantedDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private _AuthenticationService: AuthenticationService
  ) { }

  @Input() set appIsGranted(permission: string) {
    this.isGranted(permission);
  }

  private isGranted(permiso: string) {
    
    const permisos:string[] = this._AuthenticationService.getPermisos();
    if (permisos.includes(permiso)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
