import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { Casuistica } from '../../models/casuistica.model';
import { environmentLoader } from 'src/app/shared/services/environment-loader.service';
// import { environment } from '../../../environments/environment';
let environment = environmentLoader();
@Injectable({
    providedIn: 'root'
})

export class TiposCuentaValuacionService {
  
    private url = `${environment.settings.urlBE}parcelas/`;

    constructor(
        private http: HttpClient
    ) { }

    public obtenerTiposCuenta(): Observable<any[]> {
        return this.http.post<any[]>(`${this.url+'tipos-cuenta'}`, {}).pipe(
            map((res) => res),
            catchError((error) => this.errorHandler(error, 'Error al obtener las cuentas.')));
    }

    public obtenerTiposValuacion(): Observable<any[]> {
        return this.http.post<any[]>(`${this.url+'tipos-valuacion'}`, {}).pipe(
            map((res) => res),
            catchError((error) => this.errorHandler(error, 'Error al obtener las valuaciones.')));
    }
    private errorHandler(error: HttpErrorResponse, defaultError: string) {
        return throwError(error.error.text || defaultError);
    }
}

