import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';

import { RossConfigService } from 'src/@ross/services/config.service';
import { RossSidebarService } from 'src/@ross/components/sidebar/sidebar.service';

import { AuthenticationService } from 'src/app/auth/shared/auth.service';
import { Router } from '@angular/router';
import { RossNavigationService } from 'src/@ross/components/navigation/navigation.service';
import { environmentLoader } from 'src/app/shared/services/environment-loader.service';
let environment = environmentLoader();
@Component({
  selector     : 'toolbar',
  templateUrl  : './toolbar.component.html',
  styleUrls    : ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {

  rightNavbar: boolean;
  hiddenNavbar: boolean;
  private _unsubscribeAll: Subject<any>;
  public nombre: string = "";
  public apellido: string = "";

  currentUser:any = {};

  constructor(
    private _rossConfigService: RossConfigService,
    private _rossSidebarService: RossSidebarService,
    private _rossNavigationService: RossNavigationService,
    private router: Router,
    private _authService: AuthenticationService

  ) {
    //this.navigation = navigation;
    this._unsubscribeAll = new Subject();
    this.currentUser = this._authService.currentUserValue;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(){
    // Subscribe to the config changes
    this._rossConfigService.config
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((settings) => {
        this.rightNavbar = settings.layout.navbar.position === 'right';
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
    });
    this.getUsernameFromLocalStorage();
  }
  getUsernameFromLocalStorage() {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    if (user) {
    this.nombre = user.nombre;
    }
    else {this.nombre = 'Sin usuario'}
  }


  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  toggleSidebarOpen(key): void
  {
      this._rossSidebarService.getSidebar(key).toggleOpen();
  }

  logout(){
    this._authService.logout();
    this._rossNavigationService.unregister('main');
    localStorage.clear();
    // this.router.navigate([`/cat-parcelas/auth/login`]);
  }

}

