import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Usuario } from './models/usuario.model';
import { environmentLoader } from 'src/app/shared/services/environment-loader.service';
import { Router } from '@angular/router';
// import { environment } from '../../../environments/environment';
let environment = environmentLoader();

@Injectable()
export class AuthenticationService {

    private url = `parcelas/oauth-server/login`;
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

    constructor(private http: HttpClient,
                private router: Router
        ) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue() {
        return this.currentUserSubject.value;
    }
    public set currentUserValue(idCarpooler) {
        this.currentUserSubject.value.usuario.idCarpooler = idCarpooler;
        this.currentUserSubject.next(this.currentUserSubject.value);
    }

    public login(usuario: Usuario) {
        return this.http.post<any>(`parcelas/oauth-server/login`, usuario)
            .pipe(
                map((res) => {
                    localStorage.setItem('currentUser', JSON.stringify(res.user));
                    localStorage.setItem('currentToken', JSON.stringify(res.token));
                    localStorage.setItem('permisos', JSON.stringify(res.permisos));
                    localStorage.setItem('permisosUrls', JSON.stringify(res.permisosUrls));
                    this.currentUserSubject.next(res.user);
                },
                catchError((error) => throwError(error)))
            )
    }

    public isLogued() {
        const accessToken = localStorage.getItem('currentToken');
        return !!accessToken;
    }


    public loadSession() {
        return new Promise((resolve, reject) => {
            try {
                if (!!this.currentUserSubject.value) {
                    resolve();
                } else {
                    this.refreshLogin().subscribe(result => {
                        this.currentUserSubject.next(result.data);
                        resolve();
                    });
                }
            } catch (err) {
                reject(err);
            }
        })
    }

    public getUrls():Promise<string[]> {
        return this.loadSession().then(() => {
            let urls = JSON.parse(localStorage.getItem('permisosUrls'));
            return urls
        });
    }

    public refreshLogin() {
        const accessToken = localStorage.getItem('accessToken');
        return this.http.get<any>(this.url + `/users/auth/${accessToken}`)
            .pipe(map(result => {
                localStorage.setItem('currentUser', JSON.stringify(result.user));
                localStorage.setItem('currentToken', JSON.stringify(result.token));
                localStorage.setItem('permisos', JSON.stringify(result.permisos));
                this.currentUserSubject.next(result.user);
                return result;
            }));
    }

    public getPermisos() {
        return JSON.parse(localStorage.getItem('permisos'));
    }

    public obtenerUsuarios(): Observable<Usuario[]> {
        return this.http.get<Usuario[]>(`${this.url}/list`)
            .pipe(
                map((res) => res.map((usuario) => new Usuario().adapt(usuario))),
                catchError((error) => throwError(error))
            );
    }

    public logout() {
        localStorage.clear();
        this.currentUserSubject.next(null);
        this.router.navigate([`/auth/login`]);

        // this.router.navigateByUrl('/login')

    }

}
