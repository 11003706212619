import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ParcelasListComponent } from './parcelas-list.component';

const routes: Routes = [
  { path: '', component: ParcelasListComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ParcelasListRoutingModule { }
