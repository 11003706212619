import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { RossNavigationItem } from 'src/@ross/types';
import { RossNavigationService } from 'src/@ross/components/navigation/navigation.service';

@Component({
    selector   : 'ross-nav-vertical-item',
    templateUrl: './item.component.html',
    styleUrls  : ['./item.component.scss']
})
export class RossNavVerticalItemComponent implements OnInit, OnDestroy
{
    @HostBinding('class')
    classes = 'nav-item';

    @Input()
    item: RossNavigationItem;

    // Private
    private _unsubscribeAll: Subject<any>;
    // public role: Role = Role.UNKNOWN;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _rossNavigationService: RossNavigationService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

            
            // Subscribe to navigation item
        merge(
            this._rossNavigationService.onNavigationItemAdded,
            this._rossNavigationService.onNavigationItemUpdated,
            this._rossNavigationService.onNavigationItemRemoved
            ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
        // this._permissionManagerService.authAs(Role.ADMIN)
        console.log('auth')
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
