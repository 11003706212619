import { NgModule } from '@angular/core';

import { RossIfOnDomDirective } from 'src/@ross/directives/ross-if-on-dom/ross-if-on-dom.directive';
import { RossInnerScrollDirective } from 'src/@ross/directives/ross-inner-scroll/ross-inner-scroll.directive';
import { RossPerfectScrollbarDirective } from 'src/@ross/directives/ross-perfect-scrollbar/ross-perfect-scrollbar.directive';
import { RossMatSidenavHelperDirective, RossMatSidenavTogglerDirective } from 'src/@ross/directives/ross-mat-sidenav/ross-mat-sidenav.directive';

@NgModule({
    declarations: [
        RossIfOnDomDirective,
        RossInnerScrollDirective,
        RossMatSidenavHelperDirective,
        RossMatSidenavTogglerDirective,
        RossPerfectScrollbarDirective
    ],
    imports     : [],
    exports     : [
        RossIfOnDomDirective,
        RossInnerScrollDirective,
        RossMatSidenavHelperDirective,
        RossMatSidenavTogglerDirective,
        RossPerfectScrollbarDirective
    ]
})
export class RossDirectivesModule
{
}
