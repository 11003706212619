import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatToolbarModule } from '@angular/material';

import { RossSharedModule } from 'src/@ross/shared.module';

import { FooterComponent } from './footer.component';



@NgModule({
    declarations: [
        FooterComponent
    ],
    imports     : [
        RouterModule,

        MatToolbarModule,

        RossSharedModule
    ],
    exports     : [
        FooterComponent
    ]
})
export class FooterModule
{
}
