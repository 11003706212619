import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { environmentLoader } from 'src/app/shared/services/environment-loader.service';
// import { environment } from '../../../../../environments/environment';
let environment = environmentLoader()
@Injectable({
    providedIn: 'root'
})

export class ParcelasService {
  
    private url = `parcelas/parcelas`;

    constructor(
        private http: HttpClient
    ) { }

    public obtenerParcelas(filtros): Observable<any[]> {
        return this.http.post<any[]>(`${this.url}`, filtros).pipe(
            map((res) => res),
            catchError((error) => this.errorHandler(error, 'Error al obtener los Parcelas.')));
    }
    public obtenerTotalParcelas(filtros, date): Observable<any[]> {
        return this.http.post<any[]>(`${this.url}-totales`, {filtros, date}).pipe(
            map((res) => res),
            catchError((error) => this.errorHandler(error, 'Error al obtener los Parcelas.')));
    }
    public obtenerPromedioParcelas(filtros, date): Observable<any[]> {
        return this.http.post<any[]>(`${this.url}-promedios`, {filtros, date}).pipe(
            map((res) => res),
            catchError((error) => this.errorHandler(error, 'Error al obtener los Parcelas.')));
    }

    public obtenerNomenclaturasParcela(filtros): Observable<any[]> {
        console.log('filtros', filtros);
        return this.http.post<any[]>(`${this.url}/nomenclaturas`, filtros).pipe(
            map((res) => res),
            catchError((error) => this.errorHandler(error, 'Error al obtener los Parcelas.')));
    }

    public obtenerCuentasParcela(filtros): Observable<any[]> {
        console.log('filtros', filtros);
        return this.http.post<any[]>(`${this.url}/cuentas`, filtros).pipe(
            map((res) => res),
            catchError((error) => this.errorHandler(error, 'Error al obtener los Parcelas.')));
    }
    public createParcelas(parcelas) {
        return this.http.post(`${this.url}/create`, parcelas).pipe(retry(1),
        catchError((error) => this.errorHandler(error, 'Error al crear los Parcelas.')));
    }
    public deleteParcela(id: any) {
        return this.http.post(`${this.url}/delete`,id).pipe(retry(1),
        catchError((error) => this.errorHandler(error, 'Error al crear los Parcelas.')));
    }
    private errorHandler(error: HttpErrorResponse, defaultError: string) {
    if (error.error) { return throwError(error.error.text)}  else {throwError( defaultError || 'No hay datos')};
    }
}
