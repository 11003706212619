import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
  <spinner></spinner>
  <router-outlet></router-outlet>`,
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

}
