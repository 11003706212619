import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule, MatRippleModule } from '@angular/material';

import { RossNavigationComponent } from './navigation.component';
import { RossNavVerticalItemComponent } from './vertical/item/item.component';
import { RossNavVerticalCollapsableComponent } from './vertical/collapsable/collapsable.component';
import { RossNavVerticalGroupComponent } from './vertical/group/group.component';
import { IsGrantedDirective } from 'src/app/shared/services/directive/is-granted.directive';

@NgModule({
    imports     : [
        CommonModule,
        RouterModule,
        MatIconModule,
        MatRippleModule,

    ],
    exports     : [
        RossNavigationComponent,
        IsGrantedDirective
    ],
    declarations: [
        RossNavigationComponent,
        RossNavVerticalGroupComponent,
        RossNavVerticalItemComponent,
        RossNavVerticalCollapsableComponent,
        IsGrantedDirective
    ],
    providers:[]
})
export class RossNavigationModule
{
}
