import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';

import { CasuisticasService } from '../../shared/services/casuisticas/casuisticas.service';
import { ParcelasService } from '../../shared/services/parcelas/parcelas.service';
import { Valuaciones } from '../../shared/models/valuacion.model';
import { Cuentas } from '../../shared/models/cuenta.model';
import { Casuistica } from '../../shared/models/casuistica.model';
import { ValuacionesService } from '../../shared/services/valuaciones/valuaciones.service';
import { CuentasService } from '../../shared/services/cuentas/cuentas.service';
import { TiposCuentaValuacionService } from '../../shared/services/tipos-cuenta-valuacion/tipos-cuenta-valuacion.service';
import { Router } from '@angular/router';
import { environmentLoader } from 'src/app/shared/services/environment-loader.service';
let environment = environmentLoader();
@Component({
  selector: 'app-parcelas-form',
  templateUrl: './parcelas-form.component.html',
  styleUrls: ['./parcelas-form.component.scss']
})
export class ParcelasFormComponent implements OnInit {

  constructor (
    private router: Router,
    private parcelasService: ParcelasService,
    private tiposCuentaValuacionService: TiposCuentaValuacionService,
    private casuisticasService: CasuisticasService
  ) {}

  form: FormGroup;
  public tiposCuenta = [];
  public tiposValuacion = [];
  public casuisticasOpts = [];

  private get nuevaCasuistica() {
    return new FormGroup({
      casuistica: new FormControl(null, [Validators.required]),
      observaciones: new FormControl(null)
    });
  }

  public get casuisticas() {
    return this.form.get('casuisticas') as FormArray;
  }

  public get addDisabled() {
    return this.casuisticas.controls &&
      this.casuisticasOpts &&
      this.casuisticas.controls.length === this.casuisticasOpts.length;
  }

  ngOnInit () {
    //Obtener opciones Tipo Valuacion
    this.tiposCuentaValuacionService.obtenerTiposCuenta().subscribe((response: any) => {
      if(response.status === 200 && response.msg === "OK"){
        response.body.map(item => {
          this.tiposCuenta.push(item);
        });
      }
    });
    this.tiposCuentaValuacionService.obtenerTiposValuacion().subscribe((response: any) => {
      if(response.status === 200 && response.msg === "OK"){
        response.body.map(item => {
          this.tiposValuacion.push(item);
        });
      }
    });
    //Obtener opciones Casuisticas
    let filtros =  {};
    this.casuisticasService.obtenerCasuisticas(filtros).subscribe((list: any) => {
      this.casuisticasOpts = list.body;
    });

    this.form = new FormGroup({
      withAlpha: new FormControl(false, [Validators.required]),
      isNew: new FormControl(true, [Validators.required]),
      graficada: new FormControl(true,[Validators.required]),
      cuenta: new FormControl(null, [Validators.required]),
      nomenclatura: new FormControl(null, [Validators.required]),
      tipoValuacion: new FormControl(null, [Validators.required]),
      tipoCuenta: new FormControl(null, [Validators.required]),
      cantidad: new FormControl(null,[Validators.required]),
      casuisticas: new FormArray([this.nuevaCasuistica], [Validators.minLength(1)])
    });

    // withAlpha Behaviour
    this.form.get('withAlpha').valueChanges.subscribe(() => {
      this.resetAlphaFields(true);
      this.form.get('isNew').setValue(true);
    });

    // isNew Behaviour
    this.form.get('isNew').valueChanges.subscribe(newValue => {
      if (newValue == null) return;

      this.resetAlphaFields(newValue);
    });
  }

  private resetAlphaFields (enable) {
    const cuentaCtrl = this.form.get('cuenta');
    const nomenclaturaCtrl = this.form.get('nomenclatura');
    const tipoValuacionCtrl = this.form.get('tipoValuacion');
    const tipoCuentaCtrl = this.form.get('tipoCuenta');

    cuentaCtrl.reset();
    nomenclaturaCtrl.reset();
    tipoValuacionCtrl.reset();
    tipoCuentaCtrl.reset();

    if (enable) {
      nomenclaturaCtrl.enable();
      tipoValuacionCtrl.enable();
      tipoCuentaCtrl.enable();
    } else {
      nomenclaturaCtrl.disable();
      tipoValuacionCtrl.disable();
      tipoCuentaCtrl.disable();
    }
  }

  public agregarCasuistica () {
    const ctrl = this.nuevaCasuistica;
    this.casuisticas.push(ctrl);
  }

  public quitarCasuistica (index: number) {
    this.casuisticas.removeAt(index);
  }

  public submit () {
    if (this.form.valid) {
      this.parcelasService.createParcelas(this.form.value).subscribe((response: any) => {
        if(response.status === 200){
          this.router.navigate([`/pages/parcelas/listado`]);
        }
      }, error => {
        console.log('Error', error);
      });
    } else {
      console.log('%cInvalid Form', 'color: crimson', this.form.value);
    }
  }
}
