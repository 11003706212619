import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagesComponent } from './pages.component';
import { AuthGuard } from '../core/guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      // { path: 'permisos', loadChildren: './permisos/permisos.module#PermisosModule' },
      { path: 'roles', canActivate: [AuthGuard], loadChildren: './roles/roles.module#RolesModule' },
      { path: 'usuarios', canActivate: [AuthGuard], loadChildren: './usuarios/usuarios.module#UsuariosModule' },
      { path: 'parcelas', canActivate: [AuthGuard], loadChildren: './parcelas/parcelas.module#ParcelasModule' },
      { path: 'casuisticas', canActivate: [AuthGuard], loadChildren: './casuisticas/casuisticas.module#CasuisticasModule' },
      { path: 'dashboard', canActivate: [AuthGuard], loadChildren: './dashboard/dashboard.module#DashboardModule' },
      { path: '', redirectTo: 'parcelas', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
