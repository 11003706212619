import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSelected'
})
export class FilterSelectedPipe implements PipeTransform {

  transform(value: any[], selected: any[]): any {
    if (!value) return null;

    return value.filter(opt => !selected.find(selected => opt.id === selected.value.casuistica))
  }

}
