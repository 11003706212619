import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { Casuistica } from '../../models/casuistica.model';
import { environmentLoader } from 'src/app/shared/services/environment-loader.service';
// import { environment } from '../../../../../environments/environment';
let environment = environmentLoader()
@Injectable({
    providedIn: 'root'
})

export class CasuisticasService {
  
    private url = `parcelas/casuisticas`;

    constructor(
        private http: HttpClient
    ) { }

    public obtenerCasuisticas(filtros): Observable<any[]> {
        console.log('this.url', this.url);
        return this.http.post<any[]>(`${this.url}`, filtros).pipe(
            map((res) => res),
            catchError((error) => this.errorHandler(error, 'Error al obtener los Casuisticas.')));
    }
    public createOrUpdateCasuisticas(casuisticas, update?: boolean) {
        console.log('Casuisticas', casuisticas);
        return this.http.post(`${this.url}/${ update ? 'update' : 'create'}`, casuisticas).pipe(retry(1),
        catchError((error) => this.errorHandler(error, 'Error al crear los Casuisticas.')));
    }
    public deleteCasuistica(id: any) {
        return this.http.post(`${this.url}/delete`,id).pipe(retry(1),
        catchError((error) => this.errorHandler(error, 'Error al eliminar la Casuistica.')));
    }
    private errorHandler(error: HttpErrorResponse, defaultError: string) {
        if (error.error) {return throwError(error.error.text || defaultError)} else {return throwError(defaultError || 'Sin dato');}
        
    }
}

