import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../../auth/shared/auth.service';
import { environmentLoader } from 'src/app/shared/services/environment-loader.service';
let environment = environmentLoader();


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private _router: Router,
        private _authService: AuthenticationService
    ) { }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(
                catchError(err => {
                    if (err.status === 401 || err.status === 403) {
                        this._authService.logout();
                        this._router.navigate([`/cat-parcelas/#/auth/login`]);
                        if(this._router.url != `/cat-parcelas/#/auth/login`)location.reload(true);
                        return throwError(err);
                    }
                })
            );
    }

}