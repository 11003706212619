// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export let environment = {
  production: false,
  settings:{
    urlBE:"http://localhost:4200",
    urlFE:"http://localhost:4201/",
    FEUrlPrefix:'cat-parcelas/',
    BEUrlPrefix:'parcelas/'
  }
};

// module.exports = environment

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
