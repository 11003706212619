import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/core/guard/auth.guard';


const routes: Routes = [
  { path: 'listado', canActivate: [AuthGuard], loadChildren: './parcelas-list/parcelas-list.module#ParcelasListModule' },
  { path: 'crear', canActivate: [AuthGuard], loadChildren: './parcelas-form/parcelas-form.module#ParcelasFormModule' },
  { path: 'actualizar', canActivate: [AuthGuard], loadChildren: './parcelas-form/parcelas-form.module#ParcelasFormModule' },
  { path: '', redirectTo: 'listado', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ParcelasRoutingModule { }
