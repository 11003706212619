import { environment as defaultEnvironment } from '../../../environments/environment';
import { environment as preproductionEnvironment } from '../../../environments/environment.preprod';
import { environment as productionEnvironment } from "../../../environments/environment.prod";
// let defaultEnvironment = require('../../../environments/environment')
// let productionEnvironment = require('../../../environments/environment.prod')
import { enableProdMode } from '@angular/core';
import { isPrimitive } from 'util';
export const environmentLoader = function () {
  var getSubdomain = function() {
    let hostUrl = window.location.host;
    let parts = hostUrl.split(".");
    let subdomain = parts[0];
    let result = '';
    let isLocalhost = subdomain.search('localhost') >= 0;
    let isPreProd = subdomain.search('18.234.96.23') >= 0 || subdomain.search('preprod') >= 0;
    let isProd = subdomain.search('www') >= 0 || subdomain.search('rentascordoba') >= 0;

    switch (true) {
      case isLocalhost:
        result = 'localhost';
        break;
      case isPreProd:
        result = 'preproduction';
        break;
      case isProd:
        result = 'production';
        break;
      default:
        throw "No se pudo detectar el Environment.";
    }
    
    result = result.toLowerCase();
    return result;
  }

  let resultEnv;
  let environment;
  try {
    environment = getSubdomain()
  } catch (error) {
    console.error("Error al definir el environment.");
    console.error(error);
  }
  switch (environment) {
    case 'localhost':
      resultEnv = defaultEnvironment;
      break;
    case 'preproduction':
      resultEnv = preproductionEnvironment;
      break
    case 'production':
      resultEnv = productionEnvironment;
      break;
    default:
      // resultEnv = defaultEnvironment
      console.error("No se deberia setear un Environment por defecto.");
      throw "No se deberia setear un Environment por defecto.";
  }
  console.log('environment', resultEnv)
  return resultEnv;

  // var xmlhttp = new XMLHttpRequest(),
  //   method = 'GET',
  //   url = './assets/environments/environment'.concat(getSubdomain()).concat('.json');

  // xmlhttp.open(method, url, true);

  // xmlhttp.onload = function() {
  //   if (xmlhttp.status === 200) {
  //     resolve(JSON.parse(xmlhttp.responseText));
  //   } else {
  //     resolve(defaultEnvironment);
  //   }
  // };

  // xmlhttp.send();
};
