import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ParcelasService } from '../../shared/services/parcelas/parcelas.service';
import { AlertService } from '../../shared/components/alert/alert.service';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { UsuariosService } from '../../shared/services/usuarios/usuarios.service';
import { CasuisticasService } from '../../shared/services/casuisticas/casuisticas.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { environmentLoader } from 'src/app/shared/services/environment-loader.service';
//import { MatChipInputEvent } from '@angular/material/chips';
let environment = environmentLoader();
@Component({
  selector: 'app-parcelas-list',
  templateUrl: './parcelas-list.component.html',
  styleUrls: ['./parcelas-list.component.scss']
})
export class ParcelasListComponent implements OnInit {

  graficadas = ['Graficada','Sin Graficar'];
  @ViewChild('tableParcelas', { static: false}) public tableParcelas: any;
  public parcelasFormSearch: FormGroup;
  public parcelas = [];
  public usuarios = [];
  public casuisticas = [];
  public nomenclaturas = [];
  public cuentas = [];
  public graficada = [];
  public removable = true;
  public selectable = true;
  //@ViewChild('nomenclaturaInput',{ static: true}) nomenclaturaInput: ElementRef<HTMLInputElement>;
 //@ViewChild('cuentaInput',{ static: true}) cuentaInput: ElementRef<HTMLInputElement>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  
  public emptyMessage: string = 'No se han encontrado parcelas.';
  constructor(private router: Router,
    private parcelasService: ParcelasService,
    private usuariosService: UsuariosService,
    private casuisticasService: CasuisticasService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.parcelasFormSearch = new FormGroup({
      usuario: new FormControl(),
      nro_cuenta: new FormControl(),
      fecha_registro: new FormControl(),
      casuistica: new FormControl(),
      nomenclaturas: new FormControl(),
      cuentas: new FormControl(),
      graficada: new FormControl(),
     //nomenclaturaInput: new FormControl(),
     //cuentaInput: new FormControl()
    });
    this.getParcelas();
    this.getUsuarios();
    this.getCasuisticas();
  }

  setNomenclaturasToArrayFormStructure(nomenclatura=null) {
    const itemToAdd = new FormGroup({
      nomenclatura: new FormControl(nomenclatura),
    });
    (this.parcelasFormSearch.get('nomenclaturas') as FormArray).push(itemToAdd);
  }

  setCuentasToArrayFormStructure(cuenta=null) {
    const itemToAdd = new FormGroup({
      cuenta: new FormControl(cuenta),
    });
    (this.parcelasFormSearch.get('cuentas') as FormArray).push(itemToAdd);
  }
  getParcelas(){
    let filtros =  this.parcelasFormSearch.value;
    //delete filtros.nomenclaturaInput;
    //delete filtros.cuentaInput;
    console.log('filtros get parcela', filtros);
    this.parcelasService.obtenerParcelas(filtros).subscribe((response: any) => {
      console.log('getparcelas response', response)
      if (response.status === 200 && response.msg === 'OK') {
        const message = 'Se obtuvieron las parcelas correctamente.';
        this.alertService.success(message);
        this.parcelas = response.body;
        console.log('parcelas',this.parcelas)
        
      } else if (response.status === 200 && response.msg !== 'OK') {
        this.alertService.success(response.msg);
        // this.emptyMessage = response.msg;
      }
    });
  }
  getUsuarios(){
    let filtros = [];
    this.usuariosService.obtenerUsuarios(filtros).subscribe((response: any) => {
      if (response.status === 200 && response.msg === 'OK') {
        const message = 'Se obtuvieron los roles correctamente.';
        this.alertService.success(message);
        this.usuarios = response.body;
        
      } else if (response.status === 200 && response.msg !== 'OK') {
        this.alertService.success(response.msg);
        // this.emptyMessage = response.msg;
      }
    });
  }
  getCasuisticas(){
    let filtros =  {};
    this.casuisticasService.obtenerCasuisticas(filtros).subscribe((list: any) => {
      this.casuisticas = list.body;
    });
  }
  /*onSearchNomenclaturas(event){
    const value = event.target.value;
    if (value.length > 3) {
      this.parcelasService.obtenerNomenclaturasParcela({nomenclaturaLike:value}).subscribe((response: any) => {
        this.nomenclaturas = response.body;
      })
    }
  }
  onSelectNomenclatura(nomenclatura){
    let nomenclaturasSeleccionadas = this.parcelasFormSearch.get('nomenclaturas').value;
    let exist = false;
    nomenclaturasSeleccionadas.map(nomenclaturaS => {
      if (nomenclaturaS.nomenclatura === nomenclatura) {
        exist = true;
      }
    });
    if (!exist) {
      this.setNomenclaturasToArrayFormStructure(nomenclatura);
      this.parcelasFormSearch.get('nomenclaturaInput').setValue(null);
      this.nomenclaturaInput.nativeElement.value = '';
    } else {
      alert('ya ha sido seleccionada esta nomenclatura');
    }
  }
  removeNomenclatura(i){
    (this.parcelasFormSearch.get('nomenclaturas') as FormArray).removeAt(i);
  }
  onSearchCuentas(event){
    const value = event.target.value;
    if (value.length > 3) {
      this.parcelasService.obtenerCuentasParcela({cuentaLike:value}).subscribe((response: any) => {
        this.cuentas = response.body;
      })
    }
  }
  onSelectCuenta(cuenta){
    let cuentasSeleccionadas = this.parcelasFormSearch.get('cuentas').value;
    let exist = false;
    cuentasSeleccionadas.map(cuentaS => {
      if (cuentaS === cuenta) {
        exist = true;
      }
    });
    if (!exist) {
      this.setCuentasToArrayFormStructure(cuenta);
      this.parcelasFormSearch.get('cuentaInput').setValue(null);
      this.cuentaInput.nativeElement.value = '';
    } else {
      alert('ya ha sido seleccionada esta cuenta');
    }
  }*/

  removeCuenta(i){
    (this.parcelasFormSearch.get('cuentas') as FormArray).removeAt(i);
  }
  registrarParcela(){
    this.router.navigate([`/pages/parcelas/crear`]);
  }
  public toggleExpandRowParcelas(row) {
    this.tableParcelas.rowDetail.toggleExpandRow(row);
  }
}
