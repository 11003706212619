import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {

  versionApp: string = '';

  //private _unSubscribeAll: Subject<any>;

  constructor(
    //private footerService: footerService,
  ) {
    //this._unSubscribeAll = new Subs
  }

  ngOnInit(){
  }

}
