import { NgModule } from '@angular/core';
import { MatButtonModule, MatIconModule } from '@angular/material';

import { RossNavigationModule } from 'src/@ross/components';
import { RossSharedModule } from 'src/@ross/shared.module';

import { NavbarVerticalStyle1Component } from './style-1.component';


@NgModule({
    declarations: [
        NavbarVerticalStyle1Component
    ],
    imports     : [
        MatButtonModule,
        MatIconModule,

        RossSharedModule,
        RossNavigationModule
    ],
    exports     : [
        NavbarVerticalStyle1Component
    ]
})
export class NavbarVerticalStyle1Module
{
}
