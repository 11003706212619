import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { environmentLoader } from 'src/app/shared/services/environment-loader.service';
// import { environment } from '../../../environments/environment';
let environment = environmentLoader();
@Injectable({
    providedIn: 'root'
})

export class UsuariosService {
  
    private url = `${environment.settings.urlBE}parcelas/usuarios`;

    constructor(
        private http: HttpClient
    ) { }

    public obtenerUsuarios(filtros): Observable<any[]> {
        console.log('filtros', filtros);
        return this.http.post<any[]>(`${this.url}`, filtros).pipe(
            map((res) => res),
            catchError((error) => this.errorHandler(error, 'Error al obtener los usuarios.')));
    }
    public createOrUpdateUsuario(usuario, update?: boolean) {
        return this.http.post(`${this.url}/${ update ? 'update' : 'create'}`, usuario).pipe(retry(1),
        catchError((error) => this.errorHandler(error, 'Error al crear el usuario.')));
    }
    public deleteUsuario(id: any) {
        return this.http.post(`${this.url}/delete`,id).pipe(retry(1),
        catchError((error) => this.errorHandler(error, 'Error al eliminar el usuario.')));
    }
    private errorHandler(error: HttpErrorResponse, defaultError: string) {
        return throwError(error.error.text || defaultError);
    }
    public obtenerUsuariosDatos(cuit): Observable<any[]> {
        return this.http.post<any>(`${this.url}/obtenerDatos`, {cuit}).pipe(
            map((res) => res),
            catchError((error) => this.errorHandler(error, 'Error al obtener los usuarios.')));
    }

}

