import { NgModule } from '@angular/core';
import { MatAutocompleteModule, 
  MatButtonModule, 
  MatButtonToggleModule, 
  MatCardModule, 
  MatCheckboxModule, 
  MatChipsModule, 
  MatDatepickerModule, 
  MatDialogModule, 
  MatExpansionModule, 
  MatGridListModule, 
  MatIconModule, 
  MatInputModule, 
  MatListModule, 
  MatMenuModule, 
  MatNativeDateModule, 
  MatPaginatorModule, 
  MatProgressBarModule, 
  MatProgressSpinnerModule, 
  MatRadioModule, 
  MatRippleModule, 
  MatSelectModule, 
  MatSidenavModule, 
  MatSliderModule, 
  MatSlideToggleModule, 
  MatSnackBarModule, 
  MatSortModule, 
  MatTableModule, 
  MatTabsModule, 
  MatToolbarModule, 
  MatTooltipModule, 
  MatStepperModule ,
  MatBadgeModule
} from '@angular/material';

const material = [
  MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatBadgeModule,
    MatChipsModule,
    MatButtonToggleModule
];

@NgModule({
  imports: [material],
  exports: [material]
})
export class AngularMaterialModule { }
