import { environmentLoader } from "src/app/shared/services/environment-loader.service";

export let environment = {
  production: false,
  // TODO set prd url
  settings: {
      urlBE:"https://apppreprod.rentascordoba.gob.ar:443/",
      urlFE:"https://preprod.rentascordoba.gob.ar:443/",
      FEUrlPrefix:'cat-parcelas/',
      BEUrlPrefix:'parcelas/'
  }
};

// module.exports = environment;