import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { RossSharedModule } from '../../../../@ross/shared.module';
import { AngularMaterialModule } from '../../../shared/angular-material.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ParcelasListComponent } from './parcelas-list.component';
import { ParcelasListRoutingModule } from './parcelas-list-routing.module';
import { UsuariosService } from '../../shared/services/usuarios/usuarios.service';
import { RossNavigationModule } from 'src/@ross/components';

@NgModule({
    declarations: [
        ParcelasListComponent,
    ],
    imports: [
        ParcelasListRoutingModule,
        RossSharedModule,
        AngularMaterialModule,
        NgxDatatableModule,
        RossNavigationModule
    ],
    providers: [UsuariosService],
    entryComponents: [],
    schemas:[NO_ERRORS_SCHEMA]
})

export class ParcelasListModule { }
