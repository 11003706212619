import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ParcelasRoutingModule } from './parcelas-routing.module';
import { RossSharedModule } from 'src/@ross/shared.module';
import { AngularMaterialModule } from 'src/app/shared/angular-material.module';
import { ParcelasListModule } from './parcelas-list/parcelas-list.module';
import { ParcelasFormModule } from './parcelas-form/parcelas-form.module';

@NgModule({
  imports: [
    CommonModule,
    ParcelasRoutingModule,
    RossSharedModule,
    AngularMaterialModule,
    ParcelasFormModule,
    ParcelasListModule,
  ]
})
export class ParcelasModule { }
