import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './http/jwt.interceptor';
import { AuthenticationService } from '../auth/shared/auth.service';
import { AuthGuard } from './guard/auth.guard';
import { SpinnerComponent } from './spinner/spinner.component';
import { SpinnerService } from './spinner/spinner.service';
import { ErrorInterceptor } from './http/error.interceptor';
import { MatProgressSpinnerModule } from '@angular/material';

@NgModule({
  declarations: [
    SpinnerComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AuthenticationService,
    AuthGuard,
    SpinnerService
  ],
  exports: [
    SpinnerComponent
  ]
})
export class CoreModule { }
