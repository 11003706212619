import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import { AuthenticationService } from '../../auth/shared/auth.service';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private _AuthenticationService: AuthenticationService
      ){}
    
      canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
          
          /**Cambio temporal para saltar login hasta linea 44 */
          
          // if(!this._AuthenticationService.isLogued()){
          //   this._AuthenticationService.logout();
          //   Swal.fire(
          //     'Error!',
          //     'No se encuentra logueado.',
          //     'error'
          //   );
          //   return false;
          // }
          // //Verifico si tiene permisos
          //   return this._AuthenticationService.getUrls().then(
          //     (permissions:any[]) => {
          //     for(let i = 0 ; i < permissions.length ; i++){
          //       const permission = state.url;
          //       if(permission.indexOf(permissions[i]) != -1)return true;
          //     }
          //     Swal.fire(
          //       'Error!',
          //       'No tiene permisos para acceder a este recurso.',
          //       'error'
          //     );
          //     return false;
          //   });         
          return true
          
      }
    
      canActivateChild(next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.canActivate(next, state);
      }
    
      
}