import { Adapter } from 'src/app/core/adapter/adapter';

export class Usuario implements Adapter<Usuario>{
    IdUsuario: number;
    Cuil: number;
    Password: string;
    Nombre: string;
    Token: string;

    constructor() {
        this.IdUsuario = undefined;
        this.Cuil = undefined;
        this.Password = undefined;
        this.Nombre = undefined;
        this.Token = undefined;
    }

    adapt(item: any): Usuario {
        let usuario = new Usuario();
        usuario.IdUsuario = item.IdUsuario;
        usuario.Nombre = item.Nombre;
        usuario.Token = item.Token;
        usuario.Password = item.Password;
        return usuario;
    }
}