import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { ROSS_CONFIG } from 'src/@ross/services/config.service';

@NgModule()
export class RossModule
{
    constructor(@Optional() @SkipSelf() parentModule: RossModule)
    {
        if ( parentModule )
        {
            throw new Error('RossModule is already loaded. Import it in the AppModule only!');
        }
    }

    static forRoot(config): ModuleWithProviders
    {
        return {
            ngModule : RossModule,
            providers: [
                {
                    provide : ROSS_CONFIG,
                    useValue: config
                }
            ]
        };
    }
}
